import React from "react";
import "./FinishStatus.css";

const FinishStatus = () => {
  return (
    <div className="finish-status">
      <span>Đã hoàn thành</span>
    </div>
  );
};

export default FinishStatus;
